// const fetchURL = 'https://cloudinary-a.akamaihd.net/dimuz209h/image/fetch/';

export function transformImage(src, height = null, quality = 'auto') {
  console.log('transformImage', src, height, quality);

  return src;

  // if (!src) {
  //   return null;
  // }

  // if (src.includes('cloudinary')) {
  //   return src;
  // }

  // return (
  //   fetchURL +
  //   'q_' +
  //   quality +
  //   '/dpr_auto/' +
  //   (height ? 'c_scale,h_' + parseInt(height * 1.7) + '/' : '') +
  //   encodeURIComponent(src)
  // );
}
// export function transformImage(src, height = null) {
//   if (!src) {
//     return null;
//   }

//   return (
//     fetchURL +
//     +'q_auto/dpr_auto/' +
//     (height ? 'c_scale,h_' + parseInt(height * 1.7) + '/' : '') +
//     encodeURIComponent(src)
//   );
// }
